import React from 'react'
import './contact.css'

const Contact = () => {
  return (
    <section id='contact'>
      <div className="contact__container">
          <p>blake@blakeh.dev</p>
          <a href="mailto:blake@blakeh.dev" className='btn btn-primary con'>Email Me</a>
          <a href="https://github.com/BlakeDotCom" className='btn btn-primary con'>GitHub</a>
          <a href="https://www.linkedin.com/in/blake-howell-320166263" className='btn btn-primary con'>LinkedIn</a>
      </div>
    </section>
  )
}

export default Contact