import React from 'react'
import './about.css'
import {FaAward} from 'react-icons/fa'
import {FaGraduationCap} from 'react-icons/fa'


const About = () => {
  return (
    <section id='about'>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__content">
          <div className="about__cards">
         
          <article className="about__card">
              <FaGraduationCap className="about__icon"/>
              <h2>Education</h2>
              <small>University of Oklahoma <br /></small>
              <small>Class of 2023 <br /></small>
              <small>B.S. in Computer Engineering<br /></small>
            </article>

            <article className="about__card">
              <FaAward className="about__icon"/>
              <h2>Experience</h2>
              <small>7+ Years Programing <br /></small>
              <small>2+ Years Circuit Design <br /></small>
              <small>6 Months Systems Engineering at Boeing</small>
              <small>Paycom Software Developer Aug 2023 to Present</small>
            </article>
          </div>
          <p>
            I am Blake. I have passion in learning new things; mostly in topics related to math, software, and hardware design. I am a Senior majoring in computer engineering at 
            the University of Oklahoma; with coursework and other online mediums, I am constantly expanding my knowledge base and experience. I have learned many coding languages 
            through coursework and personal projects. My particular interests are in low-level programing, circuit design, microcontrollers, and FPGA's. One of my biggest strengths
            is the ability to logically and efficiently analyze problems, and break them down into smaller atomic problems. My main desktop runs on Arch Linux. I have customized my
            Linux install for maximum productivity.  
          </p>
        </div>
      </div>
    </section>
  )
}

export default About