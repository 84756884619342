import React from 'react'
import './experience.css'
import {BsPatchCheckFill} from 'react-icons/bs'

const Experience = () => {
  return (
    <section id='experience'>
      <h2>My Skills</h2>
      <div className='container experience__container'>
        <div className="exp__software">
          <h3>Software Skills</h3>
          <div className="exp__content">
            <article className="exp__details"><BsPatchCheckFill className="exp__icon"/> OOP</article>
            <article className="exp__details"><BsPatchCheckFill className="exp__icon"/> C++</article>
            <article className="exp__details"><BsPatchCheckFill className="exp__icon"/> C#</article>
            <article className="exp__details"><BsPatchCheckFill className="exp__icon"/> Java</article>
            <article className="exp__details"><BsPatchCheckFill className="exp__icon"/> JavaScript</article>
            <article className="exp__details"><BsPatchCheckFill className="exp__icon"/> Python</article>
            <article className="exp__details"><BsPatchCheckFill className="exp__icon"/> Verilog</article>
            <article className="exp__details"><BsPatchCheckFill className="exp__icon"/> Matlab</article>
            <article className="exp__details"><BsPatchCheckFill className="exp__icon"/> Linux</article>
            <article className="exp__details"><BsPatchCheckFill className="exp__icon"/> Command Line</article>
          </div>
        </div>
        <div className="exp_hardware">
          <h3>Hardware Skills</h3>
          <div className="exp__content">
            <article className="exp__details"><BsPatchCheckFill className="exp__icon"/> Circuit Design</article>
            <article className="exp__details"><BsPatchCheckFill className="exp__icon"/> Circuit Analysis</article>
            <article className="exp__details"><BsPatchCheckFill className="exp__icon"/> FPGA</article>
            <article className="exp__details"><BsPatchCheckFill className="exp__icon"/> PCB Design</article>
            <article className="exp__details"><BsPatchCheckFill className="exp__icon"/> CPU Datapath Design</article>
            <article className="exp__details"><BsPatchCheckFill className="exp__icon"/> CPU Datapath Analysis</article>
          </div>
        </div>
      </div>
      
    </section>
  )
}

export default Experience