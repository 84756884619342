import React from 'react'
import './header.css'
import CTA from './CTA'

const Header = () => {
  return (
    <div className="container header__container">
      <h5>Hello I'm</h5>
      <h1>Blake "Dot" Howell</h1>
      <h5 className="text-light">Computer Engineer</h5>
      <CTA />
      <a href="#contact" className="scroll__down">Scroll Down</a>
    </div>
  )
}

export default Header